import { ThemeProps as Prop, StaticProfiles } from '@powdr/constants';

export const StaticTheme = {
  colors: {
    [Prop.BLACK]: '#000000',
    [Prop.WHITE]: '#FFFFFF',
  },
  shades: {
    [Prop.LIGHTEST]: '#F0F0F0',
    [Prop.LIGHTER]: '#E0E0E0',
    [Prop.LIGHT]: '#A0ADAD',
    [Prop.GRAY]: '#909D9D',
    [Prop.GRAYER]: '#4D585B',
    [Prop.GRAYEST]: '#6F7C7C',
    [Prop.DARK]: '#333333',
    [Prop.DARKER]: '#222222',
    [Prop.DARKEST]: '#292929',
  },
  difficulty: {
    [Prop.BEGINNER]: '#73C053',
    [Prop.INTERMEDIATE]: '#30A8E0',
    [Prop.EXPERT]: '#000000',
  },
  social: {
    [Prop.FACEBOOK]: '#3B5998',
    [Prop.EVENTBRITE]: '#F6682F',
  },
  alerts: {
    [Prop.ACTIVE]: '#2176b8',
    [Prop.SUCCESS]: '#73c053',
    [Prop.INFO]: '#0b24fb',
    [Prop.WARNING]: '#FFB400',
    [Prop.DANGER]: '#FE4A49',
    [Prop.ALERT_WARNING_BG]: '#FFB400',
    [Prop.ALERT_WARNING_CONTENT]: '#000000',
    [Prop.ALERT_DANGER_BG]: '#FE4A49',
    [Prop.ALERT_DANGER_CONTENT]: '#FFFFFF',
  },
  [StaticProfiles.TV]: {
    [Prop.BACKGROUND]: '#FFFFFF',
    [Prop.HEADER]: '#2F6165',
    [Prop.CONTENT]: '#2F6165',
    [Prop.BORDER]: '#2F6165',
    [Prop.ICON]: '#00C2A2',
    [Prop.LINK]: '#00C2A2',
    [Prop.LINK_HOVER]: '#00C2A2',
    [Prop.ODD_EVEN_OFFSET]: '#E5EDED',
    [Prop.SIDE_NAV_ITEM_BG]: '#2F6165',
    [Prop.SIDE_NAV_ITEM_TXT]: '#FFFFFF',
    [Prop.SIDE_NAV_ITEM_ICON]: '#FFFFFF',
    [Prop.SIDE_NAV_ITEM_BG_ACTIVE]: '#3F3F3F',
    [Prop.SIDE_NAV_ITEM_TXT_ACTIVE]: '#FFFFFF',
    [Prop.SIDE_NAV_ITEM_ICON_ACTIVE]: '#FFFFFF',
    [Prop.TOP_NAV_ITEM_TXT]: '#FFFFFF',
  },
};
