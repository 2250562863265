import { ThemeProps as Prop, ColorProfiles, Components } from '@powdr/constants';

export const defaultTheme = {
  [ColorProfiles.BASE]: {
    [Prop.BACKGROUND]: '#FFFFFF',
    [Prop.HEADER]: '#2F6165',
    [Prop.CONTENT]: '#2F6165',
    [Prop.LINK_TXT]: '#00C2A2',
    [Prop.LINK_TXT_HOVER]: '#F05136',
    [Prop.BTN_BG]: '#00C2A2',
    [Prop.BTN_TXT]: '#000000',
    [Prop.BTN_BG_HOVER]: '#F05136',
    [Prop.BTN_TXT_HOVER]: '#ffffff',
    [Prop.BTN_BG_ACTIVE]: '#F05136',
    [Prop.BTN_TXT_ACTIVE]: '#ffffff',
    [Prop.BTN_BG_INACTIVE]: '#7D7D7D',
    [Prop.BTN_TXT_INACTIVE]: '#ffffff',
    [Prop.BORDER]: '#2F6165',
    [Prop.ELEMENT]: '#2F6165',
    [Prop.ELEMENT_TXT]: '#ffffff',
    [Prop.ICON]: '#00C2A2',
    [Prop.ICON_HOVER]: '#F05136',
  },
  [ColorProfiles.PRIMARY]: {
    [Prop.BACKGROUND]: '#E1F1EF',
    [Prop.HEADER]: '#2F6165',
    [Prop.CONTENT]: '#2F6165',
    [Prop.LINK_TXT]: '#F05136',
    [Prop.LINK_TXT_HOVER]: '#2F6165',
    [Prop.BTN_BG]: '#2F6165',
    [Prop.BTN_TXT]: '#E1F1EF',
    [Prop.BTN_BG_HOVER]: '#6BD9DE',
    [Prop.BTN_TXT_HOVER]: '#2F6165',
    [Prop.BTN_BG_ACTIVE]: '#6BD9DE',
    [Prop.BTN_TXT_ACTIVE]: '#2F6165',
    [Prop.BTN_BG_INACTIVE]: '#7D7D7D',
    [Prop.BTN_TXT_INACTIVE]: '#ffffff',
    [Prop.BORDER]: '#2F6165',
    [Prop.ELEMENT]: '#2F6165',
    [Prop.ELEMENT_TXT]: '#ffffff',
    [Prop.ICON]: '#F05136',
    [Prop.ICON_HOVER]: '#2F6165',
  },
  [ColorProfiles.SECONDARY]: {
    [Prop.BACKGROUND]: '#D5E4C0',
    [Prop.HEADER]: '#2F6165',
    [Prop.CONTENT]: '#2F6165',
    [Prop.LINK_TXT]: '#226E8A',
    [Prop.LINK_TXT_HOVER]: '#137D79',
    [Prop.BTN_BG]: '#226E8A',
    [Prop.BTN_TXT]: '#ffffff',
    [Prop.BTN_BG_HOVER]: '#137D79',
    [Prop.BTN_TXT_HOVER]: '#ffffff',
    [Prop.BTN_BG_ACTIVE]: '#137D79',
    [Prop.BTN_TXT_ACTIVE]: '#ffffff',
    [Prop.BTN_BG_INACTIVE]: '#7D7D7D',
    [Prop.BTN_TXT_INACTIVE]: '#ffffff',
    [Prop.BORDER]: '#2F6165',
    [Prop.ELEMENT]: '#2F6165',
    [Prop.ELEMENT_TXT]: '#ffffff',
    [Prop.ICON]: '#226E8A',
    [Prop.ICON_HOVER]: '#137D79',
  },
  [ColorProfiles.TERTIARY]: {
    [Prop.BACKGROUND]: '#2F6165',
    [Prop.HEADER]: '#E1F1EF',
    [Prop.CONTENT]: '#E1F1EF',
    [Prop.LINK_TXT]: '#6BD9DE',
    [Prop.LINK_TXT_HOVER]: '#E1F1EF',
    [Prop.BTN_BG]: '#FF9F37',
    [Prop.BTN_TXT]: '#ffffff',
    [Prop.BTN_BG_HOVER]: '#6BD9DE',
    [Prop.BTN_TXT_HOVER]: '#2F6165',
    [Prop.BTN_BG_ACTIVE]: '#6BD9DE',
    [Prop.BTN_TXT_ACTIVE]: '#2F6165',
    [Prop.BTN_BG_INACTIVE]: '#7D7D7D',
    [Prop.BTN_TXT_INACTIVE]: '#ffffff',
    [Prop.BORDER]: '#E1F1EF',
    [Prop.ELEMENT]: '#E1F1EF',
    [Prop.ELEMENT_TXT]: '#2F6165',
    [Prop.ICON]: '#FF9F37',
    [Prop.ICON_HOVER]: '#E87722',
  },
  [ColorProfiles.QUATERNARY]: {
    [Prop.BACKGROUND]: '#2F6165',
    [Prop.HEADER]: '#E1F1EF',
    [Prop.CONTENT]: '#E1F1EF',
    [Prop.LINK_TXT]: '#6BD9DE',
    [Prop.LINK_TXT_HOVER]: '#E1F1EF',
    [Prop.BTN_BG]: '#FF9F37',
    [Prop.BTN_TXT]: '#ffffff',
    [Prop.BTN_BG_HOVER]: '#6BD9DE',
    [Prop.BTN_TXT_HOVER]: '#2F6165',
    [Prop.BTN_BG_ACTIVE]: '#6BD9DE',
    [Prop.BTN_TXT_ACTIVE]: '#2F6165',
    [Prop.BTN_BG_INACTIVE]: '#7D7D7D',
    [Prop.BTN_TXT_INACTIVE]: '#ffffff',
    [Prop.BORDER]: '#E1F1EF',
    [Prop.ELEMENT]: '#E1F1EF',
    [Prop.ELEMENT_TXT]: '#2F6165',
    [Prop.ICON]: '#FF9F37',
    [Prop.ICON_HOVER]: '#E87722',
  },
  [Components.HEADER]: {
    [Prop.LOGO_BG]: '#E1F1EF',
    [Prop.NAV_BG]: '#E1F1EF',
    [Prop.NAV_ITEM_TXT_TRANSLUCENT]: '#FFFFFF',
    [Prop.NAV_ITEM_TXT_SOLID]: '#FFFFFF',
    [Prop.LOGO_FOCUS_COLOR]: '#E1F1EF',
  },
  [Components.NAVIGATION_TOGGLE]: {
    [Prop.NAV_TOGGLE_TXT]: '#2F6165',
    [Prop.NAV_TOGGLE_ICON]: '#2F6165',
    [Prop.NAV_TOGGLE_TXT_SCROLLED]: '#2F6165',
    [Prop.NAV_TOGGLE_ICON_SCROLLED]: '#2F6165',
  },
  [Components.STICKY_NAV]: {
    [Prop.BTN_TXT]: '#2F6165',
    [Prop.BTN_TXT_HOVER]: '#FF9F37',
    [Prop.BTN_BG_ACTIVE]: '#2F6165',
    [Prop.BTN_TXT_ACTIVE]: '#ffffff',
  },
  [Components.SIDEBAR]: {
    [Prop.BACKGROUND]: '#137D79',
    [Prop.CONTENT]: '#FFFFFF',
  },
  [Components.SIDEBAR_TRAY]: {
    [Prop.BACKGROUND]: '#FFFFFF',
    [Prop.CONTENT]: '#3f3f3f',
  },
};
