import {
  FontSize,
  FontFamily,
  TextTransformOptions,
} from '@powdr/constants';

const fonts = {
  [FontFamily.BASE_FONT]: {
    fontFamily: '"Montserrat"',
    textTransform: TextTransformOptions.NO_TRANSFORM,
    defaultSize: FontSize.REG50,
    scaling: 1,
  },
  [FontFamily.PRIMARY_FONT]: {
    fontFamily: '"Neuton Regular"',
    textTransform: TextTransformOptions.NO_TRANSFORM,
    defaultSize: FontSize.LRG30,
    scaling: 1,
  },
  [FontFamily.SECONDARY_FONT]: {
    fontFamily: '"Montserrat"',
    textTransform: TextTransformOptions.NO_TRANSFORM,
    defaultSize: FontSize.REG50,
    scaling: 1,
  },
};

const fontOverrides = {};

export const fontTheme = {
  fonts,
  FontSize,
  fontOverrides,
};
